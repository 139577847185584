.menu-title {
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: 800;
  text-align: left;
}
.menu-title-details {
  font-size: 12pt;
  font-weight: 800;
}

.menu-price {
  background-color: darkturquoise;
  color: white;
  font-size: 12pt;
  font-weight: bold;
}
.lugachai-head-div {
  display: table;
  width: 100%;
  height: 120px;
  border: 4px solid #a0a0a0;
  text-align: center;
  background-color: darkturquoise;
}
.lugachai-head-text {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.our-team-role {
  background-color: white;
  color: blue;
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: 800;
}

.our-team-name {
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-size: 20pt;
  font-weight: 800;
}
.our-prod-row {
  height: 300px;
}
.our-prod-why {
  color: black;
}
.our-prod-col-left {
  background-color: darkturquoise;
  color: white;
}
.order-col {
  background-color: darkturquoise;
}