.carousel-text-title {
  text-transform: uppercase;
  font-size: 3rem;
}
.carousel-text-price {
  color: cyan;
  text-transform: uppercase;
  font-size: 2rem;
}
.image-rounder {
  overflow: hidden;
  width: 375px;
  height: 375px;
  border: #ffffff 16px solid;
  border-radius: 50%;
  display: inline-block;
}